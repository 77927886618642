body {
  margin: 0;
  padding: 0;
}

blockquote + blockquote {
  margin-top: 40px;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
.bold,
.button,
footer {
  font-family: 'brandon-grotesque', sans-serif;
  font-style: normal;
  font-weight: 600;
}

.italic {
  font-style: italic;
}

h6 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 8px;
  line-height: 1;
}

p {
  margin-top: 0;
  line-height: 1.5;
}

blockquote {
  margin: 0;
}

a,
a:hover,
a:active,
a:focus {
  color: #212121;
  text-decoration: none;
  cursor: pointer;
}

p a,
p a:hover {
  text-decoration: underline;
}

.events div a {
  text-decoration: none;
}

blockquote {
  font-size: 23px;
}

.img-fluid {
  width: 100%;
}

.button {
  border: 1px solid #000;
  padding: 10px 17px;
  transition: border-color 0.4s, color 0.4s;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 15px;
  display: inline-block;
  text-align: center;
}

@media (min-width: 768px) {
  .button {
    margin-bottom: 0;
  }
}

.button:hover {
  color: #fff;
}

.button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
  opacity: 0;
  transform: scale3d(0.7, 1, 1);
  transition: transform 0.2s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.main > section:first-child {
  flex: 1;
}

article.book + article.book {
  margin-top: 60px;
}

article.book button {
  min-width: 250px;
}

article.book .row .col-cover {
  order: 1;
}

article.book .row .col-text {
  order: 2;
}

@media (min-width: 768px) {
  article.book .row .col-cover {
    order: 1;
  }
  
  article.book .row .col-text {
    order: 1;
  }
}

.homepage-cover {
  -webkit-box-shadow: 0px 3px 7px -2px rgba(140, 140, 140, 1);
  -moz-box-shadow: 0px 3px 7px -2px rgba(140, 140, 140, 1);
  box-shadow: 0px 3px 7px -2px rgba(140, 140, 140, 1);
}

.section-header,
.book-header {
  border-top: 1px solid #000;
  padding-top: 10px;
  font-size: 13px;
  padding-bottom: 5px;
  margin-bottom: 40px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .section-header,
  .book-header {
    margin-top: 0;
  }
}

.featured-logos.section-header {
  margin-top: 60px;
}

.featured-logos-img {
  margin-bottom: -60px;
}

.section-title,
.book-title {
  margin-bottom: 17px;
  font-size: 32px;
  margin-top: 0;
  line-height: 1;
}

.book-title {
  font-size: 24px;
}

p + .section-title,
p + .section-header,
.event + .section-header {
  margin-top: 15px;
}

@media (min-width: 768px) {
  p + .section-title,
  p + .section-header,
  .event + .section-header {
    margin-top: 30px;
  }
}

.words div + .section-header {
  margin-top: 45px;
}

@media (min-width: 768px) {
  .words div + .section-header {
    margin-top: 30px;
  }
}

.book {
  height: 100%;
}

.book .dropdown {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .book-cover {
    margin-bottom: 30px;
  }

  .book .dropdown {
    margin-bottom: 0;
  }
}

.book-quotes {
  -ms-flex: 1;
  flex: 1;
  margin-top: 0;
}

.books .row {
  row-gap: 30px;
}

.event-date {
  margin-right: 15px;
  width: 100px;
  min-width: 100px;
}

.event-info > h6 {
  color: #e76225;
}

.event-info p:not(:last-of-type) {
  margin-bottom: 0;
  line-height: 1.25;
}

@media (min-width: 768px) {
  .event-date {
    min-width: 160px;
  }

  .hidden-md-up {
    display: none;
  }
}

.poem {
  margin-bottom: 10px;
}

.poem-line-break {
  display: block;
}

@media (min-width: 991px) {
  .poem {
    margin-bottom: 0;
  }

  .poem-line-break {
    display: none;
  }
}
